<script setup lang="ts">

import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";

const { pushSuccess, pushError } = useNotificationsEx();
const { required, sameAs, minLength, email } = customValidators();
const { isLoggedIn, user } = useUser();

const props = withDefaults(
  defineProps<{
    type?: "defined" | "initiativ";
    job: object;
  }>(),
  {
    jub: null,
    type: "defined",
  }
);

const runtimeConfig = useRuntimeConfig();

const friendlyCaptcha = ref(null);

const jobData = reactive({
  salutation: "",
  firstname: "",
  lastname: "",
  telefon: "",
  mobil: "",
  email: "",
  eintrittsdatum: "",
  gehaltsvorstellung: "",
  message: "",
  datenschutz: false,
  captcha: false,
});


onMounted(async () => {
  if (isLoggedIn.value) {
    jobData.email = user.value?.email ?? '';
    jobData.salutation = user.value?.defaultBillingAddress?.salutation?.salutationKey ?? '';
    jobData.firstname = user.value?.firstName ?? '';
    jobData.lastname = user.value?.lastName ?? '';
    jobData.telefon = user.value?.defaultBillingAddress?.phoneNumber ?? '';
  }
});

const jobAnschreiben: Ref<Array<File>> = ref([]);
const jobArbeitszeugnis: Ref<Array<File>> = ref([]);

const rules = computed(() => ({
  salutation: {
    oneOf: (value: string) => salutationOptions.value.map((val) => val.key).includes(value),
  },
  firstname: {
    required,
    minLength: minLength(3),
  },
  lastname: {
    required,
    minLength: minLength(3),
  },
  telefon: {},
  mobil: {},
  email: {
    required,
    email,
  },
  eintrittsdatum: {
    required,
  },
  gehaltsvorstellung: {},
  message: {},
  datenschutz: {
    required: sameAs(true),
  }
}));

const $v = useVuelidate(rules, jobData);
const { triggerFriendlyCaptcha, focusOnFirstInvalidField } = useFormHelpers($v);

const salutationOptions = ref([
  {
    key: "",
    label: "Keine Angabe",
  },
  {
    key: "Divers",
    label: "Divers",
  },
  {
    key: "Frau",
    label: "Frau",
  },
  {
    key: "Herr",
    label: "Herr",
  },
]);

const loading = ref(false);

const onSubmit = async (e: Event) => {
  e.preventDefault();

  if(loading.value) return;
  loading.value = true;

  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid && (await triggerFriendlyCaptcha(jobData))) {

    try {
      const formData = new FormData();
      formData.append("jobTitle", props.job?.title);
      formData.append("jobLocation", props.job?.location.city);
      formData.append("jobType", props.job?.type);
      formData.append("salutation", jobData.salutation);
      formData.append("firstname", jobData.firstname);
      formData.append("lastname", jobData.lastname);
      formData.append("telefon", jobData.telefon);
      formData.append("mobil", jobData.mobil);
      formData.append("email", jobData.email);
      formData.append("eintrittsdatum", jobData.eintrittsdatum);
      formData.append("gehaltsvorstellung", jobData.gehaltsvorstellung);
      formData.append("message", jobData.message);
      formData.append("datenschutz", jobData.datenschutz === true ? "1" : "0");
      formData.append("captcha", jobData.captcha as unknown as string);

      for (const anschreiben of jobAnschreiben.value) {
        formData.append("anschreiben", anschreiben, anschreiben.name);
      }

      for (const arbeitszeugnis of jobArbeitszeugnis.value) {
        formData.append("arbeitszeugnis", arbeitszeugnis, arbeitszeugnis.name);
      }

      const response = await $fetch(`/api/job`, {
        method: "post",
        body: formData
      });

      if (response && response.state === "error") {
        pushError(response.statusMessage);
      }
      else
      {
        jobData.salutation = "";
        jobData.firstname = "";
        jobData.lastname = "";
        jobData.telefon = "";
        jobData.mobil = "";
        jobData.email = "";
        jobData.eintrittsdatum = "";
        jobData.gehaltsvorstellung = "";
        jobData.message = "";
        jobData.datenschutz = false;

        jobAnschreiben.value = [];
        jobArbeitszeugnis.value = [];

        pushSuccess("Vielen Dank für Ihre Nachricht!");
      }
    } catch (err: any) {
      pushError("Die Anfrage konnte nicht bearbeitet werden.");
    }

    $v.value.$reset();
    
    jobData.captcha = false;

    friendlyCaptcha.value.recreate();
  } else {
    pushError("Überprüfen Sie Ihre Eingaben, sie sind entweder fehlerhaft oder fehlen..");
    focusOnFirstInvalidField();
  }

  loading.value = false;
};

const today = new Date().toLocaleDateString('fr-ca');

const friendlyCaptchaChange = (state: any) => {
  jobData.captcha = state !== false ? state : false;
};
</script>

<template>
  <form class="mt-10 grid grid-cols-1 gap-20 xl:grid-cols-2" @submit.prevent="onSubmit">
    <fieldset>
      <legend class="my-8 text-xl">Kontaktdaten</legend>

      <SharedFormLabel label="Anrede" class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormSelect
          id="salutation"
          v-model="jobData.salutation"
          :elems="salutationOptions"
          class="w-full"
          :valid="!$v.salutation.$invalid || !$v.salutation.$dirty"
          :error="$v.salutation?.$silentErrors?.[0]?.$message ? String($v.salutation.$silentErrors[0].$message) : ''"
          @change="$v.salutation.$touch()"
        />
      </SharedFormLabel>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Vorname *">
          <SharedFormInput
            id="firstname"
            v-model="jobData.firstname"
            class="w-full"
            :valid="!$v.firstname.$invalid || !$v.firstname.$dirty"
            :error="$v.firstname?.$silentErrors?.[0]?.$message ? String($v.firstname.$silentErrors[0].$message) : ''"
            @change="$v.firstname.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Nachname *">
          <SharedFormInput
            id="lastname"
            v-model="jobData.lastname"
            class="w-full"
            :valid="!$v.lastname.$invalid || !$v.lastname.$dirty"
            :error="$v.lastname?.$silentErrors?.[0]?.$message ? String($v.lastname.$silentErrors[0].$message) : ''"
            @change="$v.lastname.$touch()"
          />
        </SharedFormLabel>
      </div>

      <div class="mt-8 sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Telefon Festnetz">
          <SharedFormInput
            id="telefon"
            v-model="jobData.telefon"
            :valid="!$v.telefon.$invalid || !$v.telefon.$dirty"
            :error="$v.telefon?.$silentErrors?.[0]?.$message ? String($v.telefon.$silentErrors[0].$message) : ''"
            @change="$v.telefon.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Telefon Mobil">
          <SharedFormInput
            id="mobil"
            v-model="jobData.mobil"
            :valid="!$v.mobil.$invalid || !$v.mobil.$dirty"
            :error="$v.mobil?.$silentErrors?.[0]?.$message ? String($v.mobil.$silentErrors[0].$message) : ''"
            @change="$v.mobil.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="E-Mail *">
        <SharedFormInput
          id="email"
          v-model="jobData.email"
          class="w-full"
          :valid="!$v.email.$invalid || !$v.email.$dirty"
          :error="$v.email?.$silentErrors?.[0]?.$message ? String($v.email.$silentErrors[0].$message) : ''"
          @change="$v.email.$touch()"
        />
      </SharedFormLabel>

      <SharedFormLabelCheck
        label="Ich akzeptiere die Datenschutzbestimmungen *" 
        class="mt-12 w-full"
        :error="$v.datenschutz.$invalid && $v.datenschutz.$dirty && 
                $v.datenschutz?.$silentErrors?.[0]?.$message ? String($v.datenschutz.$silentErrors[0].$message) : ''">
        <SharedFormCheckbox
          option="datenschutz"
          :selected="jobData.datenschutz"
          :valid="!$v.datenschutz.$invalid || !$v.datenschutz.$dirty"
          @select="jobData.datenschutz = !jobData.datenschutz"
        />
      </SharedFormLabelCheck>
    </fieldset>

    <fieldset>
      <legend class="my-8 text-xl">Bewerbung</legend>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Frühestes Eintrittsdatum *">
          <SharedFormInput
            id="eintrittsdatum"
            v-model="jobData.eintrittsdatum"
            type="date"
            :min="today"
            :valid="!$v.eintrittsdatum.$invalid || !$v.eintrittsdatum.$dirty"
            :error="
              $v.eintrittsdatum?.$silentErrors?.[0]?.$message ? String($v.eintrittsdatum.$silentErrors[0].$message) : ''
            "
            @change="$v.eintrittsdatum.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Gehaltsvorstellung (brutto / Monat)">
          <SharedFormInput
            id="gehaltsvorstellung"
            v-model="jobData.gehaltsvorstellung"
            type="number"
            :valid="!$v.gehaltsvorstellung.$invalid || !$v.gehaltsvorstellung.$dirty"
            :error="
              $v.gehaltsvorstellung?.$silentErrors?.[0]?.$message
                ? String($v.gehaltsvorstellung.$silentErrors[0].$message)
                : ''
            "
            @change="$v.gehaltsvorstellung.$touch()"
          />
        </SharedFormLabel>
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Ihr Anschreiben">
          <SharedFormUpload id="anschreiben" v-model="jobAnschreiben" />
        </SharedFormLabel>
        <SharedFormLabel label="Ihr Arbeitszeugnis">
          <SharedFormUpload id="arbeitszeugnis" v-model="jobArbeitszeugnis" />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Ihre Nachricht">
        <SharedFormTextarea
          id="message"
          v-model="jobData.message"
          class="h-37 w-full"
          :valid="!$v.message.$invalid || !$v.message.$dirty"
          :error="$v.message?.$silentErrors?.[0]?.$message ? String($v.message.$silentErrors[0].$message) : ''"
          @change="$v.message.$touch()"
        />
      </SharedFormLabel>

      <div class="sm:flex sm:items-center sm:justify-between sm:gap-8">
        <VueFriendlyCaptcha
          ref="friendlyCaptcha"
          class="my-4"
          :sitekey="runtimeConfig.public.FRIENDLY_CAPTCHA_SITEKEY"
          puzzle-endpoint__="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
          language="de"
          @done="friendlyCaptchaChange"
          @error="friendlyCaptchaChange(false)"
        />

        <div class="flex grow justify-end">
          <SharedFormButton
            class="mt-4 w-full sm:w-auto"
            layout="primary"
            size="big" 
            :loading="loading" 
            :disabled="loading"
            type="submit"
          >
            Jetzt absenden
          </SharedFormButton>
        </div>
      </div>
    </fieldset>
  </form>
</template>
